import _ from 'lodash'
import View from 'view/view'
import windowView from 'shared/view/window'
import LoginFlyoutView from 'component/header/view/login_flyout'

const HeaderView = View.extend({
    el: '.js-homepage-header',

    options: {
        join: '.js-homepage-join',
        logo: '.js-homepage-logo',
        transitionBreakpoint: 20,
    },

    _lastKnownScrollTop: 0,

    initialize() {
        this.$login = this.$('.js-header-login-button')
        this.$join = this.$(this.options.join)
        this.$logo = this.$(this.options.logo)

        // On mobile, the default header state is always the default, i.e., transparent
        // also, the header is not sticky
        if (windowView.isMobile()) {
            this.$el.addClass('is-disabled')
            return
        }

        this._lastKnownScrollTop = windowView.scrollTop()
        this._setHeaderState()

        this.loginFlyoutView = this._initializeLoginFlyout()

        this._subscribe()
    },

    openLoginFlyout() {
        this.loginFlyoutView.open()
    },

    _subscribe() {
        windowView.scroll(this._handleWindowScroll.bind(this))
    },

    _initializeLoginFlyout() {
        if (!windowView.isMobile()) {
            return this.initSubview(LoginFlyoutView, { el: this.$('.js-header-login-flyout') })
        }
    },

    _handleWindowScroll() {
        this._lastKnownScrollTop = windowView.scrollTop()

        if (window.requestAnimationFrame) {
            window.cancelAnimationFrame(this.lastAnimationFrameId)
            this.lastAnimationFrameId = window.requestAnimationFrame(this._setHeaderState.bind(this))
        } else {
            this._setHeaderState()
        }

        // Should not close the login flyout on mobile devices: scroll events may be generated by the opening
        // keyboard (iOS) or the user may need to scroll to see the bottom portion of the flyout obscured
        // by the virtual keyboard.
        if (!windowView.isMobileUserAgent()) {
            // close login popover on scroll
            this._closeLoginPopover()
        }
    },

    _setHeaderState() {
        if (this._lastKnownScrollTop > this.options.transitionBreakpoint) {
            this._makeWhite()
        } else {
            this._makeTransparent()
        }
    },

    _makeWhite() {
        this.$el.addClass('is-transitioned')
        this.$login.addClass('is-transitioned')
        this.$logo.removeClass('is-hidden')
        if (!windowView.isMobile()) {
            this.$join.removeClass('is-hidden')
        }
    },

    _makeTransparent() {
        this.$el.removeClass('is-transitioned')
        this.$login.removeClass('is-transitioned')
        this.$join.addClass('is-hidden')
        this.$logo.addClass('is-hidden')
    },

    _closeLoginPopover: _.once(function() {
        if (!_.isUndefined(this.loginFlyoutView)) {
            this.loginFlyoutView.close()
        }
    }),
})

export default HeaderView
