import _ from 'lodash'
import Controller from 'lib/controller'
import viewport from 'lib/viewport'
import HeaderView from 'bundle/home_page/view/header'
import HomePageParallaxView from 'bundle/home_page/view/parallax'
import HomePageView from 'bundle/home_page/view/home_page'
import CarouselView from 'view/carousel'
import analytics from 'service/google_analytics'
import windowView from 'shared/view/window'
import dataProvider from 'service/data_provider'
import CollapsibleList from 'component/collapsible_list/collapsible_list'
import RegistrationModalView from 'component/register/registration_modal'
import ModalView from 'component/modal/modal'

const HomePageController = Controller.extend({
    options: {
        parallaxImageSelector: '.js-parallax-hero-image',
    },

    initialize() {
        _.bindAll(this, '_inViewport', '_initHeroUmbrella', '_initHeroUsp1', '_initHeroUsp2', '_initHeroUsp3')

        this.headerView = this.initSubview(HeaderView, {
            el: '.js-homepage-header',
        })

        this.homePageView = this.initSubview(HomePageView)

        this._initHeroEntry('.js-hero-entry')

        // Only initialize USPs below the fold if not on mobile
        if (!windowView.isMobile()) {
            const lazyBlocks = {
                '.js-hero-umbrella': this._initHeroUmbrella,
                '.js-hero-usp1': this._initHeroUsp1,
                '.js-hero-usp2': this._initHeroUsp2,
                '.js-hero-usp3': this._initHeroUsp3,
            }

            this._initHeroUmbrellaParallax()

            _.each(lazyBlocks, (callback, element) => {
                windowView.scroll(_.partial(this._inViewport, element, callback))

                // If the element is in the viewport when the page loads (e.g. browser restores scroll state)
                // we immediately call the function
                if (viewport.inViewport(element)) {
                    callback(element)
                }
            })

            if (dataProvider.get('shouldShowLoginPopoverImmediately')) {
                this.headerView.openLoginFlyout()
            } else {
                this.homePageView.focusOnInput()
            }
        }

        if (dataProvider.get('isBusinessFallback')) {
            this._initRegistrationModal()
            const { showRegistrationModalRedirect } = dataProvider.get('registrationModalData')

            if (showRegistrationModalRedirect && !ModalView.getVisibleInstances().length) {
                this._registrationModal.open('business_fallback')
                this._focusRegistrationModalInput()
            }
        }

        this._initCollapsibleLists()
    },

    _initRegistrationModal() {
        this._registrationModal = this.initSubview(RegistrationModalView, {
            el: this.$el,
            contentVariables: dataProvider.get('registrationModalData'),
        })
    },

    _inViewport(element, callback) {
        if (viewport.inViewport(element, { threshold: 100 })) {
            callback(element)
        }
    },

    _initHeroEntry(element) {
        const $element = this.$(element)
        this.initSubview(HomePageParallaxView, {
            el: $element.find(this.options.parallaxImageSelector),
        })
    },

    _initCollapsibleLists() {
        this.initSubview(CollapsibleList, {
            el: '.js-seo-links',
        })
    },

    _initHeroUmbrella: _.once(function(element) {
        const $element = this.$(element)
        const $slideshowElement = $element.find('.js-hero-carousel')

        $element.addClass('is-loaded')

        this._initSlideshow($slideshowElement)
        analytics.trackEvent('access_page', 'scroll_usp', 'umbrella')
    }),

    _initHeroUmbrellaParallax() {
        this.initSubview(HomePageParallaxView, {
            el: this.$('.js-hero-umbrella').find(this.options.parallaxImageSelector),
        })
    },

    _initHeroUsp1: _.once(function(element) {
        const $element = this.$(element)
        this.initSubview(HomePageParallaxView, {
            el: $element.find(this.options.parallaxImageSelector),
        })

        $element.addClass('is-loaded')

        // Member Carousel
        this.initSubview(CarouselView, {
            el: '.js-member-carousel-wrapper',
            slidesToShow: 10,
            slidesToScroll: 10,
            speed: 1500,
            infinite: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                    },
                },
            ],
        })

        analytics.trackEvent('access_page', 'scroll_usp', 'usp_1')
    }),

    _initHeroUsp2: _.once(function(element) {
        const $element = this.$(element)
        const $slideshowElement = $element.find('.js-hero-carousel')

        this.initSubview(HomePageParallaxView, {
            el: $element.find(this.options.parallaxImageSelector),
        })

        $element.addClass('is-loaded')

        // Events Carousels
        this.initSubview(CarouselView, {
            el: '.js-event-carousel-wrapper',
            slidesToShow: 3,
            slidesToScroll: 3,
            speed: 1000,
            infinite: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
            ],
        })

        this._initSlideshow($slideshowElement)
        analytics.trackEvent('access_page', 'scroll_usp', 'usp_2')
    }),

    _initHeroUsp3: _.once(function(element) {
        const $element = this.$(element)

        this.initSubview(HomePageParallaxView, {
            el: $element.find(this.options.parallaxImageSelector),
        })

        $element.addClass('is-loaded')

        // Article Carousel
        this.initSubview(CarouselView, {
            el: '.js-article-carousel-wrapper',
            slidesToShow: 3,
            slidesToScroll: 3,
            speed: 1000,
            infinite: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
            ],
        })

        analytics.trackEvent('access_page', 'scroll_usp', 'usp_3')
    }),

    _initSlideshow($element) {
        this.initSubview(CarouselView, {
            el: $element,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: 'linear',
            fade: true,
            infinite: true,
            speed: 1000,
            addLazyloadClass: true,
        })
    },
})

export default new HomePageController()
