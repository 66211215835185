import $ from 'jquery'
import _ from 'lodash'
import View from 'view/view'
import analytics from 'service/google_analytics'
import windowView from 'shared/view/window'
import dataProvider from 'service/data_provider'
import RegistrationModalView from 'component/register/registration_modal'

const HomePageView = View.extend({
    // needs to be body here because wrapping sth. around hero components
    // would prevent them from taking the full height of the body
    el: 'body',

    events: {
        'click .js-people-carousel': '_handlePeopleCarouselClick',
        'click .js-events-carousel': '_handleEventsCarouselClick',
        'click .js-articles-carousel': '_handleArticlesCarouselClick',
        'click .js-login': '_handleLoginClick',
        'click .js-top-join-now': '_handleTopJoinNowClick',
        'click .js-bottom-join-now': '_handleBottomJoinNowClick',
        'click .js-modal-join-now': '_handleModalJoinNowClick',
    },

    options: {
        scrollUmbrellaLinkSelector: '.js-scroll-umbrella-link',
        scrollUmbrellaTargetSelector: '.js-hero-umbrella',
        focusInputSelector: '.js-focus-input',
    },

    initialize() {
        this._views = {
            registrationModal: null,
        }

        this.bootstrapElements()
        this.subscribe()

        this._initRegistrationModal()
    },

    _initRegistrationModal() {
        this._views.registrationModal = this.initSubview(RegistrationModalView, {
            el: this.$el,
            contentVariables: dataProvider.get('registrationModalData'),
        })
    },

    focusOnInput() {
        // For mobile devices focusing on a field doesn't open the virtual keyboard. The user still has to tap
        // on the field to start entering data. On iOS focusing will create a thick blue border around the field,
        // but there'll be no cursor inside. The cleanest solution is not to use focus() on mobile devices.
        if (!windowView.isMobileUserAgent()) {
            this.$(this.options.focusInputSelector).trigger('focus')
        }
    },

    bootstrapElements() {
        this.scrollUmbrellaLink = $(this.options.scrollUmbrellaLinkSelector)
        this.scrollUmbrellaTarget = $(this.options.scrollUmbrellaTargetSelector)
    },

    subscribe() {
        this.scrollUmbrellaLink.on('click', _.partial(this.scrollTo, this.scrollUmbrellaTarget))
    },

    scrollTo($target) {
        $('html, body').animate(
            {
                scrollTop: $target.offset().top,
            },
            500
        )
    },

    _handlePeopleCarouselClick() {
        analytics.trackEvent('access_page', 'click_carousel', 'people')
    },

    _handleEventsCarouselClick() {
        analytics.trackEvent('access_page', 'click_carousel', 'events')
    },

    _handleArticlesCarouselClick() {
        analytics.trackEvent('access_page', 'click_carousel', 'articles')
    },

    _handleLoginClick() {
        analytics.trackEvent('access_page', 'hp_login')
    },

    _handleTopJoinNowClick() {
        analytics.trackEvent('access_page', 'join_now', 'above_fold')
    },

    _handleBottomJoinNowClick() {
        analytics.trackEvent('access_page', 'join_now', 'bottom')
    },

    _handleModalJoinNowClick() {
        analytics.trackEvent('access_page', 'reg_modal', 'submit')
    },
})

export default HomePageView
