import $ from 'jquery'
import _ from 'lodash'
import View from 'view/view'

const ParallaxView = View.extend({
    options: {
        ratio: 0.2,
    },

    lastAnimationFrameId: null,

    initialize() {
        const ua = window.navigator.userAgent
        _.bindAll(this, '_handleResize', '_handleScroll', '_renderParallax')

        // bail if IE
        if (/MSIE/.test(ua) || /Trident/.test(ua)) {
            return
        }

        $(window).on('scroll', this._handleScroll)
        $(window).on('resize', this._handleResize)
        this.windowHeight = window.outerHeight
    },

    _handleResize() {
        this.windowHeight = window.outerHeight
    },

    /**
     * Use requestAnimationFrame to re-render the parallax elements
     */
    _handleScroll() {
        if (window.requestAnimationFrame) {
            window.cancelAnimationFrame(this.lastAnimationFrameId)
            this.lastAnimationFrameId = window.requestAnimationFrame(this._renderParallax)
        }
    },

    _renderParallax() {
        const scrollTop = document.body.scrollTop
        const topOfElement = this.el.getBoundingClientRect().top + scrollTop
        const bottomOfElement = topOfElement + this.el.offsetHeight

        // Only do work if inside the viewport
        if (scrollTop < bottomOfElement && scrollTop + this.windowHeight > topOfElement) {
            const topOfElementNoNegative = topOfElement > 0 ? topOfElement : 0
            const nextValue = (scrollTop - topOfElementNoNegative) * this.options.ratio

            const translateY = `translate3d(0, ${nextValue}px, 0)`
            this.el.style.transform = translateY
            this.el.style['-webkit-transform'] = translateY
        }
    },
})

export default ParallaxView
