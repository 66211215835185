import $ from 'jquery'
import View from 'view/view'

const CollapsibleList = View.extend({
    events: {
        'click .js-collapsible-list-trigger': '_onCollapsibleListTriggerClick',
    },

    _onCollapsibleListTriggerClick(event) {
        $(event.currentTarget).toggleClass('is-open').find('.js-collapsible-list-content').slideToggle('slow')
    },
})

export default CollapsibleList
